<template>
    <div class="dashboard">
        <div class="profile-container">
            <div class="profile">
                <img v-if="user.profile_picture" :src="profilePicture" alt="Profile">
                <span v-else>{{ user.name.charAt(0) }}</span>

                <div class="details-container">
                    <p class="name">{{ user.name }}</p>

                    <div class="detail">
                        <div class="left">
                            <p class="school">{{ user.organization || '-' }}</p>
                            <p class="value">{{ $t('subscription') }}: {{ subscription }}</p>
                        </div>

                        <div>
                            <p class="plan">{{ $t('plan_expiration') }}:</p>
                            <p class="value date">{{ user.subscription.end_date | formatDate }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-container">
            <div class="content">
                <div class="description-wrapper">
                    <p class="greetings">{{ $t('hello') }}</p>
                    <p class="description">{{ user.description || $t('write_something') }}</p>
                </div>

                <div v-if="latestResult.length > 0" class="latest-result-wrapper">
                    <div class="latest">
                        <p class="title">{{ $t('latest_result') }}</p>

                        <div class="cards-container">
                            <div
                                @click="openCard(item.assessment_type_id)"
                                class="card"
                                v-for="(item, index) in latestResult"
                                :key="index"
                                :style="{
                                    'background-image': `url(${require(`@/assets/img/results/${item.assessment_type_id}-${language}.png`)})`,
                                    'background-size': '100% 100%',
                                    'background-repeat': 'no-repeat',
                                }"
                            >

                                <div class="indicators">
                                    <div class="points">
                                        <span>{{ item.type_count }}</span>
                                    </div>

                                    <div class="percent">
                                        <span>{{ item.percent }}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div v-else class="no-result">
                    <img :src="require('@/assets/img/no_file.png')" alt="no record">
                    <p>{{ $t('no_result_found') }}</p>
                    <button @click="takeTest">{{ $t('take_test') }}</button>
                </div>
            </div>
        </div>

        <transition name="fade">
            <div class="modal-container" v-if="showModal">
                <div class="modal">
                    <img @click="showModal = false" class="close" :src="require('@/assets/img/close.svg')" alt="close">
                    <CardView :index="index"></CardView>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
    data () {
        return {
            showModal: false,
            index: 0  
        }
    },

    components: { 
        CardView: () => import('@/components/game/CardView'),
    },
    
    computed : {
        ...mapFields(['user', 'latestResult', 'language']),

        subscription () {
            let { subscription } = this.user
            return subscription.id ? `Personal Plan (${subscription.plan.slice(0,1)} Year/s) ${subscription.takes || 0} takes left.` : 'None'
        },

        profilePicture () {
            return process.env.VUE_APP_API_URL + '/storage/' + this.user.profile_picture
        }
    },

    async created () {
        await this.$store.dispatch('getLatestResult')
    },

    methods: {
        async takeTest () {
            // let res = await this.$store.dispatch('takesChecker')
            
            // if (res.data.takes < 2) {
                if (this.user.subscription.id && this.user.subscription.takes > 0) {
                    this.$router.push('card-assessment').catch(()=>{})
                } else {
                    this.$store.commit('TOGGLE_BANNER', {
                        status: true,
                        message: this.$t('no_takes_left')
                    })
                    this.$router.push('packages').catch(()=>{})
                }
            // } else {
            //     this.$store.commit('TOGGLE_BANNER', {
            //         status: true,
            //         message: this.$t('reached_limit')
            //     })
            // }
        },

        openCard (i) {
            this.index = i
            this.showModal = true
        }
    },
}
</script>

<style lang="scss" scoped>
.dashboard {
    .profile-container {
        background-color: #EBF6FA;
        padding: 35px 20px;
        display: flex;
        justify-content: center;
        

        .profile {
            width: 1180px;
            display: flex;
            flex-direction: column;

            @include desktop {
                flex-direction: row;
            }

            img {
                height: 110px;
                width: 110px;
                margin-right: 85px;
                border-radius: 50%;
            }

            span {
                height: 110px;
                width: 110px;
                border-radius: 50%;
                font-size: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: capitalize;
                background-color: #00BFA5;
                color: #ffffff;

                margin: 0 auto 15px;

                @include desktop {
                    margin-right: 30px;
                }
            }

            .details-container {
                flex: 1;
                text-align: center;

                @include desktop {
                    text-align: unset;
                    padding-right: 60px;
                }

                .name {
                    font-family: 'Crimson Pro',  sans-serif;
                    font-weight: 600;
                    font-size: 28px;
                    margin-bottom: 15px;
                }

                .detail {
                    display: flex;
                    flex-direction: column;

                    @include desktop {
                        flex-direction: row;
                    }

                    .left {
                        flex: 1;
                        margin-bottom: 15px;
                    }

                    .school {
                        color: #B8B8B8;
                        margin-bottom: 10px;
                    }

                    .value {
                        font-size: 14px;
                        color: #221E1F;
                    }

                    .plan {
                        color: #B8B8B8;
                        font-size: 14px;
                        margin-bottom: 5px;
                    }

                    .date {
                        color: #00A54F;
                        font-size: 18px;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .content-container {
        padding: 20px;
        display: flex;
        justify-content: center;

        .content {
            // width: 1180px;

            @include desktop {
                width: 1180px;
            }

            .description-wrapper {
                margin-bottom: 20px;

                @include desktop {
                    padding-left: 195px;
                }

                .greetings {
                    font-family: 'Crimson Pro', sans-serif;
                    font-weight: 600;
                    font-size: 24px;
                    margin-bottom: 14px;
                }
    
                .description {
                    color: #B8B8B8;
                }
            }
        }

        .latest-result-wrapper {
            @include desktop {
                padding-left: 195px;
                padding-right: 60px;
            }

            .latest {
                .title {
                    font-family: 'Crimson Pro', sans-serif;
                    font-weight: 600;
                    font-size: 24px;
                    margin-bottom: 20px;
                }

                .cards-container {
                    display: flex;
                    flex-flow: row wrap;
                    // width: 650px;
                    cursor: pointer;

                    // @include desktop {
                    //     width: 965px;
                    // }

                    .card {
                        width: 140px;
                        height: 205px;
                        margin-right: 10px;
                        margin-bottom: 10px;
                        background-color: #C4C4C4;
                        padding-bottom: 10px;
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: center;
                        border-radius: 4px;

                        .indicators {
                            display: flex;

                            .points {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                background-color: #45B171;
                                margin-right: 10px;
    
                                span {
                                    color: #ffffff;
                                    font-size: 14px;
                                }
                            }
    
                            .percent {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                background-color: #00AEEF;
                                border: 2px solid #ffffff;
    
                                span {
                                    color: #ffffff;
                                    font-size: 14px;
                                }
                            }
                        }

                    }
                }

            }
        }

        .no-result {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 110px;

            img {
                width: 200px;
                height: 150px;
                margin-bottom: 15px;
            }

            p {
                color: #B8B8B8;
                margin-bottom: 15px;
            }

            button {
                background-color: #00AEEF;
                width: 120px;
                font-weight: 600;
                font-size: 14px;
                color: #ffffff;
            }
        }
    }

    .fade-enter {
        opacity: 0;
    }
    .fade-enter-active {
        transition: opacity .5s;
    }
    .fade-leave {
        opacity: 1;
    }
    .fade-leave-active {
        transition: opacity .5s;
        opacity: 0;
    }

    .modal-container {
        position: fixed;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        // background: #000000a1;
        z-index: 999999;
        overflow-y: auto;


        .modal {
            width: 100%;
            background: #FFF8F1;

            position: absolute;
            padding: 20px;
            overflow: auto;

            .close {
                cursor: pointer;
                position: absolute;
                top: 20px;
                right: 20px;
            }
        }
    }
}
</style>  